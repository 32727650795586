import { ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Steps,
  Typography,
  Upload,
  UploadProps,
  message,
  theme,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { AdmissionApi, DocumentApi, UserSendingConfigurations } from "src/api";
import { RequireDocuments } from "src/api/auth/admission.api";
import { SendingDocument, SendingDocumentEnd } from "src/assets/images";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import RichTextEditor from "src/components/RichTextEditor/RichTextEditor";
import { REACT_APP_BASE_PATH } from "src/constants";
import { countryStates } from "src/data";
import { ExpiredSessionError, dictionary, i18n } from "src/utils";
import styled from "styled-components";

const { Text, Title, Link } = Typography;
const { Header, Footer, Content } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  height: 64,
  paddingInline: 50,
  lineHeight: "128px",
  background: "#F5F5F5",
};

const contentStyle: React.CSSProperties = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "200px",
};

const footerStyle: React.CSSProperties = {
  textAlign: "center",
};

const rowStyle: React.CSSProperties = {
  height: "100vh",
};

const contentTop: React.CSSProperties = {
  height: "50vh",
  padding: "20px 0px",
  justifyContent: "center",
};

const contentBottom: React.CSSProperties = {
  marginLeft: "64px",
  marginTop: "32px",
  marginRight: "64px",
};

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

const StyledText = styled(Text)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const SendingDocuments: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(-1);
  const [file, setFile] = useState("");
  const [urlUploadedFile, setUrlUploadedFile] = useState<string>("");
  const [enableSend, setEnableSend] = useState(false);
  const [enableNext, setEnableNext] = useState(false);
  const [params, setParams] = useSearchParams();
  const [personalization, setPersonalization] = useState<SendingPersonalization>();

  const [requireDocuments, setRequireDocuments] = useState<RequireDocuments>({
    deadline: 30,
    employerName: "",
    documentCodes: ["RG"],
    contractText: "",
    employeeId: 0,
    admissionStatus: 1,
    attachmentCodes: ["RG"],
  });
  const [withoutRequireDocuments, setWithoutRequireDocuments] = useState<boolean>(true);
  const { show } = useIntercom();
  const [form] = useForm();

  const [downloadingContract, setDownloadingContract] = useState(false);

  const stepsStyle: React.CSSProperties = {
    color: "#fff",
    width: "50%",
    backgroundColor: personalization?.principalColor ?? "#884EA6",
  };

  async function downloadContract() {
    message.destroy();
    setDownloadingContract(true);
    const response = await AdmissionApi.downloadContract(requireDocuments.employeeId).catch((_) => false);
    if (!response) {
      message.error(i18n(dictionary.label.error_download_contract));
    }
    setDownloadingContract(false);
  }

  async function onFinish(values: any) {
    values.employeeId = requireDocuments.employeeId;
    if (values.documentCode === "CO") {
      values.contract = requireDocuments.contractText;
      AdmissionApi.sendDocument(values)
        .then((res) => {
          next();
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.SEND_DOCUMENT_ERROR.title),
              message: i18n(dictionary.error.SEND_DOCUMENT_ERROR.message),
              onOk: () => onFinish(values),
              onClickLink: () => show(),
            });
          }
        });
    } else {
      AdmissionApi.sendDocument(values)
        .then((res) => {
          next();
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.SEND_DOCUMENT_ERROR.title),
              message: i18n(dictionary.error.SEND_DOCUMENT_ERROR.message),
              onOk: () => onFinish(values),
              onClickLink: () => show(),
            });
          }
        });
    }
  }

  async function onFinishSendDocument() {
    AdmissionApi.finishSendDocument(requireDocuments.employeeId).catch((error) => {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.SEND_DOCUMENT_ERROR.title),
          message: i18n(dictionary.error.SEND_DOCUMENT_ERROR.message),
          onOk: () => onFinishSendDocument(),
          onClickLink: () => show(),
        });
      }
    });
  }

  const getChosedPersonalization = async () => {
    try {
      const email = params.get("email");
      const res = await UserSendingConfigurations.chosed(email ?? "");
      setPersonalization(res);
    } catch (error) {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title),
          message: i18n(dictionary.error.GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message),
          onOk: () => onFinish(personalization),
          onClickLink: () => show(),
        });
      }
    }
  };

  async function getRequireDocuments() {
    const email = params.get("email");
    AdmissionApi.getSendingDocuments(email ?? "")
      .then((res: RequireDocuments) => {
        if (res.deadline <= 0) {
          Modal.error({
            title: "Prazo expirado",
            content: "O prazo para envio dos documentos expirou.",
          });
          setIsModalOpen(false);
        } else if (res.admissionStatus >= 2) {
          Modal.error({
            title: "Documentos já foram enviados",
            content: "Os documentos já foram enviados, por favor aguarde a finalização do processo de admissão.",
          });
          setIsModalOpen(false);
        }
        setRequireDocuments(res);
        setWithoutRequireDocuments(false);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_REQUIRE_DOCUMENT.title),
            message: i18n(dictionary.error.GET_REQUIRE_DOCUMENT.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        console.error(error);
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getChosedPersonalization();
      await getRequireDocuments();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  function getBase64(img: any, callback: (result: any) => void) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const bytesToMb = (bytes: number) => bytes / 1000000;

  const fileIsTooLarge = (file: any) => bytesToMb(file.size) > 1;

  const fileIsImage = (file: any) => file.type.startsWith("image/");

  function handleUpload(info: any, documentCode: string) {
    if (info.event) {
      if (!fileIsImage(info.file)) {
        Modal.warning({
          title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_IMAGE.title),
          content: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_IMAGE.message),
        });
        return;
      }

      if (fileIsTooLarge(info.file)) {
        Modal.warning({
          title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_SIZE.title),
          content: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_SIZE.message),
        });
        return;
      }

      getBase64(info.file.originFileObj, (fileURL) => {
        const fileBuffer = fileURL.replace(/(data:image\/(jpeg)*(gif)*(png)*(gif)*\;(base64),)/g, "");
        setFile(fileBuffer);
        DocumentApi.uploadFile(fileBuffer, documentCode, requireDocuments.employeeId)
          .then((res: any) => {
            setUrlUploadedFile(REACT_APP_BASE_PATH + "/download/" + res.url);
          })
          .catch((error) => {
            if (!(error instanceof ExpiredSessionError)) {
              GoodErrorMessage({
                title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR.title),
                message: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR.message),
                onClickLink: () => show(),
                onOk: () => handleUpload(info, documentCode),
              });
            }
            console.error(error);
          });
      });
    }
  }

  const props: UploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    // onChange(info) {
    //   if (info.file.status !== "uploading") {
    //   }
    //   if (info.file.status === "done") {
    //     message.success(`Arquivo ${info.file.name} carregado com sucesso!`);
    //   } else if (info.file.status === "error") {
    //     message.error(`Ocorreu um erro ao carregar o arquivo ${info.file.name}.`);
    //   }
    // },
    showUploadList: false,
    accept: "image/*",
    multiple: false,
  };

  function cleanImage() {
    setFile("");
    setUrlUploadedFile("");
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const next = async () => {
    const next = current + 1;
    if (steps[next].key === "CO") {
      await getRequireDocuments();
    }
    setCurrent(next);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: <StyledText color="white">Documento de identidade (RG)</StyledText>,
      key: "RG",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "RG" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Documento de identidade (RG)
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="identity"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="00.000.000-00"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="identityAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("RG"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "RG")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">CPF</StyledText>,
      key: "CPF",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CPF" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      CPF
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="cpf"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000.000.000-00"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="cpfAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CPF"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CPF")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">PIS/PASEB</StyledText>,
      key: "PIS",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "PIS" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      PIS/PASEB
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="pis"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000.00000.00-0"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="pisAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("PIS"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "PIS")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Carteira de trabalho</StyledText>,
      key: "CT",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CT" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Carteira de trabalho
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="workCardNumber"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="workCardAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CT"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CT")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Space>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Série
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="workCardSerie"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        placeholder="000"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        UF
                      </StyledTitle>
                    }
                    style={{ minWidth: "20px" }}
                  >
                    <Form.Item
                      name="workCardState"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Select
                        allowClear
                        size="large"
                      >
                        {countryStates.map((countryState) => (
                          <Select.Option
                            key={countryState.abbreviation}
                            title={countryState.abbreviation}
                            value={countryState.abbreviation}
                          >
                            {countryState.abbreviation}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Space>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Título eleitoral</StyledText>,
      key: "TE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "TE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Título eleitoral
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="voterRegistrationNumber"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="voterRegistrationAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("TE"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "TE")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Space>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Seção
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="voterRegistrationSection"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        placeholder="000"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Zona eleitoral
                      </StyledTitle>
                    }
                    style={{ minWidth: "20px" }}
                  >
                    <Form.Item
                      name="voterRegistrationZone"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Select
                        allowClear
                        size="large"
                      >
                        {countryStates.map((countryState) => (
                          <Select.Option
                            key={countryState.abbreviation}
                            title={countryState.abbreviation}
                            value={countryState.abbreviation}
                          >
                            {countryState.abbreviation}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Space>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">CNH</StyledText>,
      key: "CNH",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CNH" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      CNH
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="driverLicense"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="00000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="driverLicenseAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CNH"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CNH")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={4}
                    >
                      Validade
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    name="driverLicenseExpirationDate"
                    noStyle
                    rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                  >
                    <Row gutter={8}>
                      <Col span={19}>
                        <DatePicker
                          size="large"
                          placeholder="Selecione a data"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Número de reservista</StyledText>,
      key: "NR",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "NR" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Número de reservista
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="reservistNumber"
                          noStyle
                          rules={[{ required: false, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="reservistAttachment"
                            noStyle
                            rules={[
                              {
                                required: false,
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "NR")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={4}
                    >
                      Série
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    name="reservistSerie"
                    noStyle
                    rules={[{ required: false, message: i18n(dictionary.message.requiredField) }]}
                  >
                    <Row gutter={8}>
                      <Col span={19}>
                        <Input
                          size="large"
                          placeholder="S"
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Comprovante de endereço</StyledText>,
      key: "CE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "10%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Comprovante de endereço
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Logradouro
                      </StyledTitle>
                    }
                  >
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="publicPlace"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="Logradouro"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="addressAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CE"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CE")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Space>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          Número
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="addressNumber"
                        noStyle
                      >
                        <Input
                          placeholder="Número"
                          size="large"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          Complemento
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="complement"
                        noStyle
                      >
                        <Input
                          placeholder="Complemento"
                          size="large"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          CEP
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="zipCode"
                        noStyle
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Input
                          placeholder="CEP"
                          size="large"
                        />
                      </Form.Item>
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Space>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Bairro
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="zone"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        placeholder="Bairro"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Cidade
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="city"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        placeholder="Cidade"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Estado
                      </StyledTitle>
                    }
                    style={{ minWidth: "20px" }}
                  >
                    <Form.Item
                      name="state"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Select
                        allowClear
                        size="large"
                      >
                        {countryStates.map((countryState) => (
                          <Select.Option
                            key={countryState.abbreviation}
                            title={countryState.abbreviation}
                            value={countryState.abbreviation}
                          >
                            {countryState.abbreviation}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Space>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Comprovante de matrícula em ensino superior ou técnico</StyledText>,
      key: "CMEST",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CMEST" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Número de matrícula
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          // name="dataValue"
                          name="enrollmentEducation"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="enrollmentEducationAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CMEST"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CMEST")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Termo de compromisso de estágio (TCE)</StyledText>,
      key: "TCE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "TCE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Número de registro do documento
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          // name="dataValue"
                          name="internshipCommitmentTerm"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="internshipCommitmentTermAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("TCE"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "TCE")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Gênero</StyledText>,
      key: "GE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "GE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Gênero
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    name="dataValue"
                    noStyle
                    rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                  >
                    <Select
                      placeholder="Escolha o seu gênero"
                      size="large"
                    >
                      <Select.Option value="1">Mulher cisgênero</Select.Option>
                      <Select.Option value="2">Homem cisgênero</Select.Option>
                      <Select.Option value="3">Mulher transgênero</Select.Option>
                      <Select.Option value="4">Homem transgênero</Select.Option>
                      <Select.Option value="5">Não-binário</Select.Option>
                      <Select.Option value="6">Agênero</Select.Option>
                      <Select.Option value="7">Bigênero</Select.Option>
                      <Select.Option value="8">Trigênero</Select.Option>
                      <Select.Option value="9">Pangênero</Select.Option>
                      <Select.Option value="11">Outros</Select.Option>
                      <Select.Option value="12">Prefiro não declarar</Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Contrato</StyledText>,
      key: "CO",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "4%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="vertical"
              style={{ marginLeft: "8%", marginRight: "8%", width: "100%" }}
            >
              <StyledTitle
                color="gray"
                level={2}
              >
                Contrato
              </StyledTitle>
              <Card style={{ width: "100%" }}>
                {requireDocuments.contractText && (
                  <RichTextEditor
                    content={JSON.parse(requireDocuments.contractText)}
                    style={{
                      height: 290,
                    }}
                    readOnly
                  />
                )}
              </Card>
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CO" })}
                style={{ width: "100%", marginTop: 8, justifyContent: "center", textAlign: "center" }}
                validateMessages={validateMessages}
              >
                <Form.Item>
                  <Form.Item name="accept-contract">
                    <Checkbox onChange={() => setEnableNext(true)}>
                      <Text>Li e aceito os termos do contrato de trabalho</Text>
                    </Checkbox>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Enviar</StyledText>,
      key: "EN",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
              src={
                personalization?.logo?.length === 0
                  ? SendingDocument
                  : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
              }
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row style={{ textAlign: "center" }}>
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "center",
                marginLeft: "16%",
                marginRight: "16%",
                height: "60%",
                marginBottom: "64px",
              }}
            >
              <StyledTitle
                color="gray"
                level={5}
              >
                Antes de enviar verifique se todos os documentos foram incluídos/anexados. Ao clicar no botão abaixo os
                seus documentos serão enviados e logo revisados.
              </StyledTitle>
            </Space>
          </Row>
        </Space>
      ),
    },
  ];

  const filteredSteps = steps.filter(
    (step) => requireDocuments.documentCodes.includes(step.key) || ["CO", "EN"].includes(step.key),
  );

  const items = filteredSteps.map((item) => ({ key: item.key, title: item.title }));

  return (
    <>
      {isModalOpen && (
        <>
          {current === -1 ? (
            <Row style={rowStyle}>
              <Col
                span={12}
                style={stepsStyle}
              >
                <Row style={contentTop}>
                  <Image
                    width="auto"
                    height="100%"
                    src={SendingDocument}
                    alt="SendingDocumentStart"
                    preview={false}
                  />
                </Row>
                <Row style={contentBottom}>
                  <Row>
                    <StyledTitle color="white">{personalization?.frase}</StyledTitle>
                  </Row>
                  <Row>
                    <StyledTitle
                      color="gray"
                      level={2}
                    ></StyledTitle>
                  </Row>
                </Row>
              </Col>
              <Col span={12}>
                <Space
                  style={{
                    width: "100%",
                    justifyContent: "end",
                    marginBottom: "20%",
                    textAlign: "end",
                  }}
                >
                  <Image
                    style={{ marginTop: "64px", marginRight: "64px", height: "50%", width: "50%" }}
                    src={
                      personalization?.logo?.length === 0
                        ? SendingDocument
                        : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
                    }
                    alt="Logo"
                    preview={false}
                  />
                </Space>
                <Row>
                  <Space
                    direction="horizontal"
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <StyledTitle
                      style={{ fontSize: "128px", marginBottom: "8px" }}
                      color={personalization?.secondaryColor}
                    >
                      Oiê!
                    </StyledTitle>
                  </Space>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <Space
                    direction="horizontal"
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      marginLeft: "16%",
                      marginRight: "16%",
                      height: "60%",
                      marginBottom: "64px",
                    }}
                  >
                    <StyledTitle
                      color="gray"
                      level={5}
                    >
                      Boas vindas ao processo de conferência de admissão! Aqui você poderá conferir os documentos
                      enviados por {requireDocuments.employerName}. Te desejamos um ótimo trabalho!
                    </StyledTitle>
                  </Space>
                </Row>
                <Row>
                  <Space
                    direction="horizontal"
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <Button
                      icon={<ArrowRightOutlined />}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      style={{ background: "#6164C2" }}
                      onClick={() => next()}
                      disabled={withoutRequireDocuments}
                    >
                      Iniciar
                    </Button>
                  </Space>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row style={rowStyle}>
              <Col
                span={12}
                style={stepsStyle}
              >
                <Row>
                  <Col span={16}>
                    <Space direction="vertical">
                      <Space
                        direction="horizontal"
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          marginLeft: "16%",
                          marginRight: "16%",
                          height: "60%",
                          marginTop: "15%",
                        }}
                      >
                        <StyledTitle
                          color="white"
                          level={3}
                        >
                          {i18n(dictionary.label.document_list)}
                        </StyledTitle>
                      </Space>
                      <Space
                        direction="horizontal"
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          marginLeft: "16%",
                          marginRight: "16%",
                          height: "50%",
                        }}
                      >
                        <Steps
                          current={current}
                          items={items}
                          direction="vertical"
                        />
                      </Space>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space align="end">
                      <Image
                        width="auto"
                        height="50%"
                        src={SendingDocument}
                        alt="SendingDocument"
                        preview={false}
                      />
                    </Space>
                  </Col>
                </Row>
                <Row style={contentBottom}>
                  <Row>
                    <StyledTitle color="white">{personalization?.frase}</StyledTitle>
                  </Row>
                </Row>
              </Col>
              <Col span={12}>
                <div>{filteredSteps[current].content}</div>
                <Row>
                  <Space
                    direction="horizontal"
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <div style={{ marginTop: 24 }}>
                      {current > 0 && current < filteredSteps.length - 1 && (
                        <Button
                          type="primary"
                          onClick={() => prev()}
                          style={{ background: "#6164C2" }}
                          size="large"
                        >
                          <ArrowLeftOutlined />
                          Voltar
                        </Button>
                      )}
                      {current < filteredSteps.length - 1 && (
                        <Button
                          type="primary"
                          onClick={() => {
                            form.submit();
                            cleanImage();
                          }}
                          style={{ margin: "0 8px", background: "#6164C2" }}
                          size="large"
                          disabled={!enableNext && filteredSteps[current].key === "CO"}
                        >
                          <ArrowRightOutlined />
                          Próximo
                        </Button>
                      )}
                      {current === filteredSteps.length - 1 && (
                        <Button
                          type="primary"
                          onClick={async () => {
                            message.success("Etapas concluídas!");
                            setIsModalOpen(false);
                            await onFinishSendDocument();
                          }}
                          style={{ margin: "0 8px", background: "#6164C2" }}
                          size="large"
                          icon={<ArrowUpOutlined />}
                          disabled={!enableSend}
                        >
                          Enviar
                        </Button>
                      )}
                    </div>
                  </Space>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <Space
                    direction="horizontal"
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      marginLeft: "16%",
                      marginRight: "16%",
                      height: "60%",
                      marginTop: "64px",
                    }}
                  >
                    {current < filteredSteps.length - 1 ? (
                      <StyledTitle
                        color="gray"
                        level={5}
                      >
                        Faltam {requireDocuments.deadline} dias para expirar o prazo de envio!
                      </StyledTitle>
                    ) : (
                      <Checkbox onChange={() => setEnableSend(true)}>
                        <Text>Aceito os</Text>
                        <Link
                          href="https://www.qrpoint.com.br/politica-de-privacidade/"
                          underline
                          target="_blank"
                        >
                          {" "}
                          termos de uso e política de privacidade{" "}
                        </Link>
                        <Text>para o envio dos documentos.</Text>
                      </Checkbox>
                    )}
                  </Space>
                </Row>
              </Col>
            </Row>
          )}
        </>
      )}

      {!isModalOpen && (
        <Space
          direction="vertical"
          style={{ width: "100%" }}
          size={[0, 48]}
        >
          <Layout>
            <Header style={headerStyle}>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "end",
                  textAlign: "end",
                }}
              >
                <Image
                  style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
                  src={
                    personalization?.logo?.length === 0
                      ? SendingDocument
                      : `${REACT_APP_BASE_PATH}/download/${personalization?.logo}`
                  }
                  alt="Logo"
                  preview={false}
                />
              </Space>
            </Header>
            <Content style={contentStyle}>
              <Image
                style={{ height: "25%", width: "25%" }}
                src={SendingDocumentEnd}
                alt="Foguete subindo"
                preview={false}
              />
              <Space
                direction="vertical"
                wrap
                size={0}
                style={{ display: "flex" }}
              >
                <StyledTitle
                  style={{ fontSize: "78px", marginBottom: "16px" }}
                  color="#66C790"
                >
                  Deu tudo certo!
                </StyledTitle>
                <StyledTitle
                  level={2}
                  style={{
                    marginLeft: "16%",
                    marginRight: "16%",
                  }}
                >
                  A Admissão Digital deseja que a sua nova jornada seja incrível :)
                </StyledTitle>
                <StyledTitle
                  color="gray"
                  level={3}
                  style={{
                    marginLeft: "30%",
                    marginRight: "30%",
                  }}
                >
                  Qualquer dúvida não deixe de entrar em contato com o departamento pessoal da sua empresa!
                </StyledTitle>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    background: "#6164C2",
                    minHeight: "42px",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                  loading={downloadingContract}
                  onClick={downloadContract}
                >
                  {i18n(dictionary.label.download_contract)}
                </Button>
              </Space>
            </Content>
            <Footer style={footerStyle}>Desenvolvido com carinho pela Admissão Digital</Footer>
          </Layout>
        </Space>
      )}
    </>
  );
};

export default SendingDocuments;
