import { DateTime } from "luxon";
import { ExpiredSessionError, ResponseError } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";
import { IntercomApi } from "..";
import request from "../request";

export type AdmissionForm = {
  employerId: string;
  employmentRelationshipId: number;
  name: string;
  email: string;
  jobOccupation: string;
  departmentId: number;
  salary: string;
  admissionDate?: moment.Moment;
  workExperiencePeriodInDays: number;
  shiftType: number;
  firstEmployment: boolean;
  onboarding: boolean;
  admissionDeadlineDays?: number;
};

export interface ChartTable {
  id: number;
  name: string;
  status: number;
  date: DateTime;
}

interface AmountMonthChart {
  month: string;
  admissions: number;
}

export interface AmountMonth {
  chartData: AmountMonthChart[];
  tableData: ChartTable[];
}

export interface AmountDateStatusChart {
  dateStatus: string;
  admissions: number;
}

// export interface DateStatusChartTable {
//   id: number;
//   name: string;
//   status: string;
//   date: DateTime;
// }

export interface AmountDateStatus {
  chartData: AmountDateStatusChart[];
  tableData: ChartTable[];
}

export interface RequireDocuments {
  deadline: number;
  employerName: string;
  documentCodes: string[];
  contractText: string;
  employeeId: number;
  admissionStatus: number;
  attachmentCodes: string[];
}

export const create = async (admission: AdmissionForm): Promise<boolean> => {
  try {
    const { data } = await request.post("/admissions", admission);
    return data.success;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const sendEmail = async (email: string): Promise<boolean> => {
  try {
    const { data } = await request.post("/admissions/send-email", { email });
    return data.success;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const finish = async (employeeId: number): Promise<{ success: boolean; requiredDocumentErrors: number[] }> => {
  try {
    const { data } = await request.post("/admissions/finish", { employeeId });
    if (data.success) IntercomApi.update(INTERCOM_FIELDS.AD_COMPLETE_ADMISSION);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else if (error.response?.data) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetch = async (): Promise<Employee[]> => {
  try {
    const { data } = await request.get("/admissions");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const calculateAmountMonth = async (body: { startDate: Date; endDate: Date }): Promise<AmountMonth> => {
  try {
    const { data } = await request.post("/admissions/amount-month", body);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const calculateAmountDateStatus = async (body: {
  startDate: Date;
  endDate: Date;
}): Promise<AmountDateStatus> => {
  try {
    const { data } = await request.post("/admissions/amount-date-status", body);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const getSendingDocuments = async (email: string): Promise<RequireDocuments> => {
  try {
    const { data } = await request.get(`sending-documents/${email}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const sendDocument = async (body: any) => {
  try {
    const { data } = await request.post("sending-documents", body);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const finishSendDocument = async (employeeId: number): Promise<boolean> => {
  try {
    const { data } = await request.post("/admissions/finish-send-document", { employeeId });
    return data.success;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const amountLimit = async (): Promise<Boolean> => {
  try {
    const { data } = await request.get(`/admissions/verify/amount-limit`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const downloadContract = async (employeeId: number) => {
  try {
    const { data } = await request.get(`/admissions/download-contract/${employeeId}`, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "contract.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const createFranchiseRequest = async (amount: number) => {
  try {
    return await request
      .post(`/admissions/create-franchise-request`, { amount })
      .then((response) => response.status === 201 && response.data.success)
      .catch((_) => false);
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateAdmissionFlowStep = async (employeeId: number, admissionFlowId: string) => {
  try {
    return await request
      .post("/admissions/update-admission-flow-step", { employeeId, admissionFlowId })
      .then((response) => response.status === 200 && response.data.success)
      .catch((_) => false);
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
