import { MaskedInput as Antd5MaskInput } from "antd-5-mask-input";

export default function MaskedInput(props: any) {
  const handlePaste = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };
  return (
    <Antd5MaskInput
      onPaste={handlePaste}
      {...props}
    >
      {props.children}
    </Antd5MaskInput>
  );
}
