import {
  AuditOutlined,
  ContainerOutlined,
  EyeOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  Form as AntdForm,
  Button,
  DatePicker,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Row,
  Steps,
  Switch,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { DefaultOptionType } from "antd/lib/select";
import dayjs from "dayjs";
import { LocalStorage } from "lowdb/browser";
import { useContext, useEffect, useState } from "react";
import Notifier from "react-desktop-notification";
import { NumberFormatBase } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { addNotification, loadingNotification, removeNotification } from "src/actions/session.action";
import { AdmissionApi } from "src/api";
import { Card, Form, FormItem, InputGroup, Option, Select, SelectInsert } from "src/components";
import CustomContentInfoMessage from "src/components/Modal/CustomContentInfoMessage";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import GoodInfoMessage from "src/components/Modal/GoodInfoMessage";
import InfoMessage from "src/components/Modal/InfoMessage";
import { Data, LowSyncWithLodash } from "src/components/NotificationsList";
import { REACT_APP_BASE_PATH } from "src/constants";
import { SocketContext } from "src/contexts";
import { useSessionContext } from "src/contexts/session.context";
import MaskedInput from "src/modules/CustomMaskedInput";

import { Routes } from "src/routes";
import { Storage } from "src/services";
import { ExpiredSessionError, capitalizeFirstLetter, dictionary, formatToBRL, i18n, numericRegExp } from "src/utils";
import { ESocialAdmissionReceipt } from "src/utils/types/ESocialAdmissionReceipt";
import { v4 } from "uuid";

const { Text, Link } = Typography;

interface FinishAdmissionButtonProps {
  form: FormInstance;
  style: any;
  onClick: () => void;
  disabled: boolean;
}

const FinishAdmissionButton: React.FC<React.PropsWithChildren<FinishAdmissionButtonProps>> = ({
  form,
  children,
  style,
  onClick,
  disabled,
}) => {
  const [finishable, setFinishable] = useState<boolean>(false);

  // Watch all values
  const values = useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setFinishable(true))
      .catch(() => setFinishable(false));
  }, [form, values]);

  return (
    <Button
      style={style}
      disabled={!finishable || disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

type Props = {
  employmentRelationships: EmploymentRelationship[];
  jobOccupationRegisters: JobOccupationRegister[];
  employers: Employer[];
  departments: Department[];
  workerCategories: WorkerCategory[];
  laborRegimes: LaborRegime[];
  pensionRegimes: PensionRegime[];
  paymentUnitsRemuneration: PaymentUnitRemuneration[];
  typesEvent: TypeEvent[];
  admissionTypes: AdmissionType[];
  admissionIndicators: AdmissionIndicator[];
  journeisRegime: JourneyRegime[];
  activityNatures: ActivityNature[];
  monthProfessionalCategories: MonthProfessionalCategory[];
  typesEmploymentContract: TypeEmploymentContract[];
  registrationTypes: RegistrationType[];
  typesOfWorkingDay: TypeOfWorkingDay[];
  partTimeContracts: PartTimeContract[];
  legalHypothesisTemporaryWorker: LegalHypothesisTemporaryWorker[];
  // journeyTypes: JourneyType[];

  insertBenefit(value: string, employerId: string): void;
  benefits: Benefit[];
  managers: User[];
  onSubmit(values: any): void;
  onCancel(): void;
  employee: Employee | undefined;
  setEmployee: React.Dispatch<React.SetStateAction<Employee | undefined>>;
  insertDepartment(value: string, employerId: string): void;
  loading: boolean;
};

function TabAdmissionalData({
  employmentRelationships,
  jobOccupationRegisters,
  employers,
  departments,
  workerCategories,
  laborRegimes,
  pensionRegimes,
  paymentUnitsRemuneration,
  typesEvent,
  admissionTypes,
  admissionIndicators,
  journeisRegime,
  activityNatures,
  monthProfessionalCategories,
  typesEmploymentContract,
  typesOfWorkingDay,
  registrationTypes,
  partTimeContracts,
  legalHypothesisTemporaryWorker,
  insertBenefit,
  benefits,
  managers,
  onSubmit,
  onCancel,
  employee,
  setEmployee,
  insertDepartment,
  // journeyTypes,
  loading,
}: Props): JSX.Element {
  const [employerId, setEmployerId] = useState("");
  const [sentToEsocial, setSentToEsocial] = useState<boolean>(false);
  const [hasSalaryDescription, setHasSalaryDescription] = useState(false);
  const [isTemporaryCollaborator, setIsTemporaryCollaborator] = useState(false);
  const [isPositionJobCumulative, setIsPositionJobCumulative] = useState(false);
  const [isTpContrDay, setIsTpContrDay] = useState(false);
  const [isTpContrFact, setIsTpContrFact] = useState(false);
  const [hasAssuranceClause, setHasAssuranceClause] = useState(false);
  const [hasWeeklyShiftNighttime, setHasWeeklyShiftNighttime] = useState(false);
  const [isWeeklyJourneyModalOpen, setIsWeeklyJourneyModalOpen] = useState(false);
  const socket = useContext(SocketContext);
  const { sessionState, sessionDispatch } = useSessionContext();
  const [userId, setUserId] = useState<number>(0);
  const [disableFinishAdmission, setDisableFinishAdmission] = useState(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const handlePaste = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const adapter = new LocalStorage<Data>("qrpoint-notifications");
  const defaultData: Data = {
    notifications: [],
  };
  const db = new LowSyncWithLodash(adapter, defaultData);
  db.read();

  function sendESocialEvent(employeeId: number, certPass: string) {
    sessionDispatch(loadingNotification(true));
    const socketId = v4();
    socket.emit("send-esocial-admission-event-batch", {
      id: socketId,
      params: {
        certPass: certPass,
        employeeId: employeeId,
        userId: userId,
      },
    });

    socket.on(socketId, (data: any) => {
      const notificationCollection = db.data.notifications;
      let title = "Evento de admissão enviado para o eSocial com sucesso!";
      let content =
        "O recibo de processamento do evento estará disponível pelos próximos 20 " +
        "minutos. Clique aqui para fazer o download do recibo!";
      let notifier = "Verifique as suas notificações no sistema ou clique aqui para baixar.";
      let link = REACT_APP_BASE_PATH + decodeURIComponent(data.url);

      if (data.error) {
        title = "Não foi possível enviar o evento de admissão para o eSocial!";
        content = "Ocorreu um erro ao enviar o evento de admissão para o eSocial. Por favor, tente novamente.";
        notifier = "Verifique as suas notificações no sistema.";
        link = "";

        if (data.errorCode) {
          if (data.errorCode === "E_SOCIAL_FIRST_VALIDATION_ERROR") {
            content = data.errorMessage.replace("E_SOCIAL_FIRST_VALIDATION_ERROR: ", "");
          } else {
            content = i18n(dictionary.error.start + data.errorCode + dictionary.error.message);
          }
        }

        Notifier.focus(title, notifier, link, window.location.origin + "/res/logo-simples.svg");
      } else {
        Notifier.start(title, notifier, link, window.location.origin + "/res/logo-simples.svg");
      }

      notificationCollection.push({
        userId,
        id: socketId,
        expires: dayjs().add(20, "minutes").valueOf(),
        title,
        content,
        link,
      });
      db.data.notifications = notificationCollection;
      db.write();

      sessionDispatch(
        addNotification({
          userId,
          id: socketId,
          title,
          content,
          link,
        }),
      );

      sessionDispatch(loadingNotification(false));
      socket.removeListener(socketId);
      setTimeout(
        () => {
          sessionDispatch(removeNotification(socketId));
        },
        1000 * 60 * 19,
      ); //20 minutos para deletar a notificação
    });
  }

  const [form] = useForm();
  const [formPass] = AntdForm.useForm();

  const filteredBenefits = employerId
    ? benefits.filter(
        (benefit) =>
          employee?.benefits.find((employeeBenefit) => employeeBenefit.id == benefit.id) ||
          benefit.employers?.find((employerBenefit) => employerBenefit.employerId == employerId),
      )
    : [];

  const filteredDepartments = employerId
    ? departments.filter(
        (department) =>
          employee?.additionalData?.departmentId == department.id ||
          department.employers?.find((employerDepartment) => employerDepartment.employerId == employerId),
      )
    : [];

  const { show } = useIntercom();
  const navigate = useNavigate();

  async function finishAdmission(employeeId: number): Promise<boolean> {
    return AdmissionApi.finish(employeeId)
      .then((result) => {
        if (!result.success) {
          let specificMessage = " ";

          if (result.requiredDocumentErrors.length > 0) {
            i18n(dictionary.info.FINISH_ADMISSION_INFO.specificMessage);
            for (const documentError of result.requiredDocumentErrors) {
              specificMessage += i18n(dictionary.info.FINISH_ADMISSION_INFO.requiredDocument[documentError]) + ", ";
            }

            specificMessage = specificMessage.slice(0, -2) + ".";
          }

          GoodInfoMessage({
            title: i18n(dictionary.info.FINISH_ADMISSION_INFO.title),
            message: i18n(dictionary.info.FINISH_ADMISSION_INFO.message) + specificMessage,
          });

          return false;
        }

        if (sentToEsocial) {
          GoodInfoMessage({
            title: i18n(dictionary.success.FINISH_ADMISSION_SUCCESS.title),
            message: i18n(dictionary.success.FINISH_ADMISSION_SUCCESS.message),
          });
        } else {
          Modal.success({
            title: i18n(dictionary.success.FINISH_ADMISSION_SUCCESS.title),
          });
        }

        return true;

        //navigate(Routes.EMPLOYEE_EDIT + `?id=${employeeId}`);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          if (error.response?.data) {
            GoodInfoMessage({
              title: i18n(dictionary.error.FINISH_ADMISSION_ERROR.title),
              message: i18n(dictionary.error.start + error.response?.data + dictionary.error.message),
            });
          } else {
            GoodErrorMessage({
              title: i18n(dictionary.error.FINISH_ADMISSION_ERROR.title),
              message: i18n(dictionary.error.FINISH_ADMISSION_ERROR.message),
              onOk: () => finishAdmission(employeeId),
              onClickLink: () => show(),
            });
          }
        }
        console.error(error);
        return false;
      });
  }

  const labelTootip = () => {
    return (
      <Row>
        {i18n(dictionary.label.send_to_esocial)}
        <Tooltip title={i18n(dictionary.message.send_to_esocial)}>
          <QuestionCircleOutlined style={{ marginLeft: 10 }} />
        </Tooltip>
      </Row>
    );
  };

  const typeEmploymentContractOnchange = (value: number) => {
    switch (value) {
      case 2:
        setIsTpContrDay(true);
        setIsTpContrFact(false);
        break;
      case 3:
        setIsTpContrFact(true);
        setIsTpContrDay(false);
        break;
      default:
        setIsTpContrFact(false);
        setIsTpContrDay(false);
    }
  };

  const getUserId = async () => setUserId(Storage.getUserId());

  const formPassHandleSubmit = (values: any) => {
    sendESocialEvent(employee?.id ?? 0, values.password);
    navigate(Routes.EMPLOYEE);
  };

  function completeStep() {
    modalApi.confirm({
      title: i18n(dictionary.label.confirmation_required),
      content: `${i18n(dictionary.label.complete_step_warning)} ${Number(employee?.additionalData.flowStep) + 1}?`,
      okText: i18n(dictionary.label.yes),
      cancelText: i18n(dictionary.label.no),
      onOk: async () => {
        if (!!employee && !!employee?.additionalData && !!employee?.additionalData.admissionFlowId) {
          const response = await AdmissionApi.updateAdmissionFlowStep(
            employee?.id,
            employee?.additionalData.admissionFlowId,
          );
          notificationApi.destroy();
          if (response) {
            notificationApi.success({
              message: i18n(dictionary.success.ADMISSION_FLOW_EXECUTE_STEP_SUCCESS.message),
              description: i18n(dictionary.success.ADMISSION_FLOW_EXECUTE_STEP_SUCCESS.description),
            });
            setEmployee((prevEmployee) => {
              if (prevEmployee) {
                const newFlowStep = Number(prevEmployee.additionalData.flowStep) + 1;
                const flowStepsLength = Number(prevEmployee.additionalData?.admissionFlow?.flowSteps.length);
                return {
                  ...prevEmployee,
                  additionalData: {
                    ...prevEmployee.additionalData,
                    flowStep: newFlowStep,
                    admissionStatus:
                      newFlowStep === prevEmployee.additionalData?.admissionFlow?.flowSteps[flowStepsLength - 1].index
                        ? 3
                        : prevEmployee.additionalData.admissionStatus,
                  },
                };
              }
            });
          } else {
            notificationApi.error({
              message: i18n(dictionary.error.ADMISSION_FLOW_EXECUTE_STEP_ERROR.message),
              description: i18n(dictionary.error.ADMISSION_FLOW_EXECUTE_STEP_ERROR.description),
            });
          }
        }
      },
    });
  }

  useEffect(() => {
    getUserId();
    form.setFieldsValue({
      ...employee,
      ...employee?.additionalData,
      ...employee?.eSocialData,
      assuranceClause: employee?.eSocialData?.assuranceClause ?? false,
      admissionDate: employee?.admissionDate ? dayjs(employee?.admissionDate) : undefined,
      fgtsDate: employee?.eSocialData?.fgtsDate ? dayjs(employee?.eSocialData.fgtsDate) : undefined,
      endContractDate: employee?.eSocialData?.endContractDate
        ? dayjs(employee?.eSocialData.endContractDate)
        : undefined,
      benefits: employee?.benefits?.map((benefit) => benefit.id),
    });
    setEmployerId(employee?.employerId ?? "");
    setSentToEsocial(employee?.additionalData?.hasEsocial ?? false);
    setIsPositionJobCumulative(employee?.eSocialData?.isPositionJobCumulative ?? false);
    setIsTemporaryCollaborator(employee?.eSocialData?.isTemporaryEmployee ?? false);
    setHasSalaryDescription(employee?.eSocialData?.hasSalaryDescription ?? false);
    setHasSalaryDescription(
      employee?.eSocialData?.paymentUnitRemunerationId
        ? employee?.eSocialData?.paymentUnitRemunerationId == 6 || employee?.eSocialData?.paymentUnitRemunerationId == 7
        : false,
    );

    typeEmploymentContractOnchange(employee?.eSocialData?.employmentContractTypeId ?? 0);
    setHasWeeklyShiftNighttime(employee?.eSocialData?.hasWeeklyShiftNightTime ?? false);
    setHasAssuranceClause(employee?.eSocialData?.assuranceClause ?? false);
  }, []);

  let showEsocialButton = true;
  if (employee?.additionalData?.eSocialReceipt) {
    const eSocialReceipt = JSON.parse(employee.additionalData.eSocialReceipt) as ESocialAdmissionReceipt;
    if (!eSocialReceipt?.occurrences) {
      showEsocialButton = false;
    }
  }

  return (
    <>
      {modalContextHolder}
      {notificationContextHolder}
      <Form
        form={form}
        onSubmit={(values) => {
          setDisableFinishAdmission(false);
          onSubmit({ ...values, tab: "2" });
        }}
        onCancel={() => onCancel()}
        submitText={i18n(dictionary.action.update_save)}
        onValuesChange={() => {
          setDisableFinishAdmission(true);
        }}
        onSubmitFailed={() => {
          setDisableFinishAdmission(true);
        }}
        loading={loading}
        setSubmittable={setSubmittable}
      >
        <Card
          icon={<ContainerOutlined />}
          title={i18n(dictionary.label.admissional_data)}
        >
          <FormItem
            label={capitalizeFirstLetter(i18n(dictionary.entity.employment_relationship))}
            name="employmentRelationshipId"
            rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            hasFeedback
            validateFirst
          >
            <Select>
              {employmentRelationships.map((relationship) => (
                <Option
                  key={relationship.id}
                  title={relationship.description}
                  value={relationship.id}
                >
                  {relationship.description}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label={i18n(dictionary.label.registry)}
            name="registration"
            rules={[{ required: true }, { max: 50 }]}
            hasFeedback
            validateFirst
          >
            <MaskedInput
              placeholder={i18n(dictionary.insert, {
                item: i18n(dictionary.label.registry),
              })}
              mask={[{ mask: numericRegExp }]}
              onPaste={handlePaste}
            />
          </FormItem>
          {sentToEsocial && (
            <>
              <FormItem
                label={capitalizeFirstLetter(i18n(dictionary.label.type_of_employment_regime))}
                name="employmentRegimeId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {laborRegimes.map((regime) => (
                    <Option
                      key={regime.id}
                      title={regime.name}
                      value={regime.id}
                    >
                      {regime.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label={capitalizeFirstLetter(i18n(dictionary.label.type_of_pension_scheme))}
                name="pensionRegimeId"
                rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {pensionRegimes.map((regime) => (
                    <Option
                      key={regime.id}
                      title={regime.name}
                      value={regime.id}
                    >
                      {regime.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </>
          )}
          <FormItem
            label={i18n(dictionary.label.salary)}
            name="salary"
            rules={[{ required: true }, { max: 13 }]}
            hasFeedback
            validateFirst
          >
            <NumberFormatBase
              customInput={Input}
              defaultValue="000"
              format={formatToBRL}
            />
          </FormItem>
          <FormItem>
            <InputGroup>
              <FormItem
                label={i18n(dictionary.label.experience_period)}
                name="workExperiencePeriodInDays"
                rules={[{ type: "number", min: 0, max: 9999 }]}
                initialValue={0}
                hasFeedback
                validateFirst
              >
                <InputNumber
                  addonAfter={i18n(dictionary.label.days)}
                  controls={false}
                />
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.experience_period_extension)}
                name="extensionOfWorkExperiencePeriodInDays"
                rules={[{ type: "number", min: 0, max: 9999 }]}
                initialValue={0}
                hasFeedback
                validateFirst
              >
                <InputNumber
                  addonAfter={i18n(dictionary.label.days)}
                  controls={false}
                />
              </FormItem>
            </InputGroup>
          </FormItem>
          {sentToEsocial && (
            <>
              <FormItem
                label={capitalizeFirstLetter(i18n(dictionary.label.payment_unit_remuneration))}
                name="paymentUnitRemunerationId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select
                  onChange={(value) => {
                    if (value == 6 || value == 7) {
                      setHasSalaryDescription(true);
                    } else {
                      setHasSalaryDescription(false);
                    }
                  }}
                >
                  {paymentUnitsRemuneration.map((paymentUnitRemuneration) => (
                    <Option
                      key={paymentUnitRemuneration.id}
                      title={paymentUnitRemuneration.name}
                      value={paymentUnitRemuneration.id}
                    >
                      {paymentUnitRemuneration.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              {hasSalaryDescription && (
                <FormItem
                  label={
                    <Row>
                      {i18n(dictionary.label.salary_description)}
                      <Tooltip title={i18n(dictionary.message.salary_description)}>
                        <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                      </Tooltip>
                    </Row>
                  }
                  name="salaryDescription"
                  rules={[
                    { required: hasSalaryDescription, message: i18n(dictionary.message.requiredField) },
                    { max: 255 },
                  ]}
                  hasFeedback
                  validateFirst
                >
                  <Input />
                </FormItem>
              )}
            </>
          )}
          <FormItem
            label={
              <Row>
                {i18n(dictionary.label.job_occupation_register)}
                <Tooltip title={i18n(dictionary.message.job_occupation_register)}>
                  <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                </Tooltip>
              </Row>
            }
            name="cboIdentifier"
            rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }, { max: 255 }]}
            hasFeedback
            validateFirst
          >
            <Select
              showSearch
              onSelect={(_: any, option: DefaultOptionType) =>
                form.setFieldsValue({
                  jobOccupation: String(option.key),
                })
              }
            >
              {jobOccupationRegisters.map((job) => (
                <Option
                  key={job.name}
                  title={`${job.code} – ${job.name}`}
                  value={job.code}
                >
                  {job.code} – {job.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label={i18n(dictionary.label.occupation)}
            name="jobOccupation"
            rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }, { max: 53 }]}
            hasFeedback
            validateFirst
          >
            <Input />
          </FormItem>
          <FormItem
            label={i18n(dictionary.label.admission_date)}
            name="admissionDate"
            rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
            hasFeedback
            validateFirst
          >
            <DatePicker format={[i18n(dictionary.dateFormat)]} />
          </FormItem>
          <FormItem>
            <InputGroup>
              <FormItem
                label={capitalizeFirstLetter(i18n(dictionary.entity.employer))}
                name="employerId"
                rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select
                  onChange={(value: string) => {
                    setEmployerId(value);
                    form.setFieldValue("benefits", []);
                    form.setFieldValue("departmentId", undefined);
                  }}
                >
                  {employers.map((employer) => (
                    <Option
                      key={employer.id}
                      title={employer.shortName ?? employer.fullName}
                      value={employer.id}
                    >
                      {employer.shortName ?? employer.fullName}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              {employerId && (
                <FormItem
                  label={capitalizeFirstLetter(i18n(dictionary.label.benefit))}
                  name="benefits"
                  rules={[{ required: false }]}
                  hasFeedback
                  validateFirst
                >
                  <SelectInsert
                    mode="multiple"
                    onInsert={(value) => insertBenefit(value, employerId)}
                  >
                    {filteredBenefits.map((benefit) => (
                      <Option
                        key={benefit.id}
                        title={benefit.name}
                        value={benefit.id}
                      >
                        {benefit.name}
                      </Option>
                    ))}
                  </SelectInsert>
                </FormItem>
              )}
            </InputGroup>
          </FormItem>

          {employerId && (
            <FormItem
              label={i18n(dictionary.label.department)}
              name="departmentId"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
              hasFeedback
              validateFirst
            >
              <SelectInsert onInsert={(value) => insertDepartment(value, employerId)}>
                {filteredDepartments.map((department) => (
                  <Option
                    key={department.id}
                    title={department.description}
                    value={department.id}
                  >
                    {department.description}
                  </Option>
                ))}
              </SelectInsert>
            </FormItem>
          )}

          {/* <FormItem
          label={i18n(dictionary.label.manager)}
          name="manager"
          rules={[{ required: false }]}
        >
          <Select>
            {managers.map((manager) => (
              <Option
                key={manager.id}
                title={manager.name}
                value={manager.id}
              >
                {manager.name}
              </Option>
            ))}
          </Select>
        </FormItem> */}
          {sentToEsocial && (
            <>
              <FormItem
                label={
                  <Row>
                    {i18n(dictionary.label.type_of_event_ESocial)}
                    <Tooltip title={i18n(dictionary.message.type_of_event_ESocial)}>
                      <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                    </Tooltip>
                  </Row>
                }
                name="eSocialEventTypeId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {typesEvent.map((typeEvent) => (
                    <Option
                      key={typeEvent.id}
                      title={typeEvent.name}
                      value={typeEvent.id}
                    >
                      {typeEvent.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.admission_type)}
                name="admissionTypeId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {admissionTypes.map((admissionType) => (
                    <Option
                      key={admissionType.id}
                      title={admissionType.name}
                      value={admissionType.id}
                    >
                      {admissionType.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.admission_indicator)}
                name="admissionIndicatorId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {admissionIndicators.map((admissionIndicator) => (
                    <Option
                      key={admissionIndicator.id}
                      title={admissionIndicator.name}
                      value={admissionIndicator.id}
                    >
                      {admissionIndicator.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.journey_regime)}
                name="workingDayRegimeId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {journeisRegime.map((journeyRegime) => (
                    <Option
                      key={journeyRegime.id}
                      title={journeyRegime.name}
                      value={journeyRegime.id}
                    >
                      {journeyRegime.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.activity_nature)}
                name="eSocialActivityNatureId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {activityNatures.map((activityNature) => (
                    <Option
                      key={activityNature.id}
                      title={activityNature.name}
                      value={activityNature.id}
                    >
                      {activityNature.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.month_professional_category)}
                name="monthYearId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {monthProfessionalCategories.map((monthProfessionalCategory) => (
                    <Option
                      key={monthProfessionalCategory.id}
                      title={monthProfessionalCategory.name}
                      value={monthProfessionalCategory.id}
                    >
                      {monthProfessionalCategory.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.cnpj_union_representative)}
                name="cnpjUnionRepresentative"
                rules={[{ max: 14 }, { min: 14 }]}
                hasFeedback
                validateFirst
              >
                <MaskedInput mask={[{ mask: numericRegExp }]} />
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.fgts_date)}
                required
                name="fgtsDate"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <DatePicker format={[i18n(dictionary.dateFormat)]} />
              </FormItem>
              <FormItem>
                <InputGroup>
                  {/* <FormItem
                    label={i18n(dictionary.label.is_temporary_collaborator)}
                    name="isTemporaryEmployee"
                  >
                    <Row>
                      <Switch
                        checkedChildren={i18n(dictionary.label.yes)}
                        unCheckedChildren={i18n(dictionary.label.no)}
                        onChange={(checked: boolean) => {
                          form.setFieldsValue({ isTemporaryEmployee: checked });
                          setIsTemporaryCollaborator(checked);
                        }}
                        checked={isTemporaryCollaborator}
                      />
                    </Row>
                  </FormItem> */}
                  <FormItem
                    label={i18n(dictionary.label.is_position_job_cumulative)}
                    name="isPositionJobCumulative"
                    hasFeedback
                    validateFirst
                  >
                    <Row>
                      <Switch
                        checkedChildren={i18n(dictionary.label.yes)}
                        unCheckedChildren={i18n(dictionary.label.no)}
                        onChange={(checked: boolean) => {
                          form.setFieldsValue({ isPositionJobCumulative: checked });
                          setIsPositionJobCumulative(checked);
                        }}
                        checked={isPositionJobCumulative}
                      />
                    </Row>
                  </FormItem>
                </InputGroup>
              </FormItem>
              {isTemporaryCollaborator && (
                <>
                  <FormItem
                    label={i18n(dictionary.label.data_on_temporary_work)}
                    name="legalHypothesisTemporaryWorkerId"
                    rules={[{ required: isTemporaryCollaborator, message: i18n(dictionary.message.requiredField) }]}
                    hasFeedback
                    validateFirst
                  >
                    <Select>
                      {legalHypothesisTemporaryWorker.map((legalHypothesisTemporaryWorker) => (
                        <Option
                          key={legalHypothesisTemporaryWorker.id}
                          title={legalHypothesisTemporaryWorker.name}
                          value={legalHypothesisTemporaryWorker.id}
                        >
                          {legalHypothesisTemporaryWorker.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem
                    label={i18n(dictionary.label.cnpj_cpf_temporary_employee)}
                    name="cnpjCpfTemporaryEmployee"
                    rules={[
                      { required: isTemporaryCollaborator, message: i18n(dictionary.message.requiredField) },
                      { max: 14 },
                    ]}
                    hasFeedback
                    validateFirst
                  >
                    <MaskedInput mask={[{ mask: numericRegExp }]} />
                  </FormItem>
                </>
              )}
              <FormItem
                label={i18n(dictionary.label.employee_category_code)}
                name="workerCategoryId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select showSearch>
                  {workerCategories.map((category) => (
                    <Option
                      key={category.name}
                      title={`${category.code} – ${category.name}`}
                      value={category.id}
                    >
                      {category.code} – {category.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.type_employment_contract)}
                name="employmentContractTypeId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select onChange={typeEmploymentContractOnchange}>
                  {typesEmploymentContract.map((typeEmploymentContract) => (
                    <Option
                      key={typeEmploymentContract.id}
                      title={typeEmploymentContract.name}
                      value={typeEmploymentContract.id}
                    >
                      {typeEmploymentContract.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              {(isTpContrDay || isTpContrFact) && (
                <FormItem
                  label={i18n(dictionary.label.end_contract_date)}
                  name="endContractDate"
                  rules={[{ required: isTpContrDay, message: i18n(dictionary.message.requiredField) }]}
                  hasFeedback
                  validateFirst
                >
                  <DatePicker format={[i18n(dictionary.dateFormat)]} />
                </FormItem>
              )}
              {isTpContrDay && (
                <FormItem
                  label={i18n(dictionary.label.assurance_clause)}
                  name="assuranceClause"
                  rules={[{ required: isTpContrDay, message: i18n(dictionary.message.requiredField) }]}
                  hasFeedback
                  validateFirst
                >
                  <Switch
                    checkedChildren={i18n(dictionary.label.yes)}
                    unCheckedChildren={i18n(dictionary.label.no)}
                    onChange={(checked: boolean) => {
                      form.setFieldsValue({ hasAssuranceClause: checked });
                      setHasAssuranceClause(checked);
                    }}
                    checked={hasAssuranceClause}
                  />
                </FormItem>
              )}
              {isTpContrFact && (
                <FormItem
                  label={i18n(dictionary.label.determining_object)}
                  name="determiningObject"
                  rules={[{ required: isTpContrFact }, { max: 255 }]}
                  hasFeedback
                  validateFirst
                >
                  <Input />
                </FormItem>
              )}
              <FormItem
                label={
                  <Row>
                    {i18n(dictionary.label.worker_establishment)}
                    <Tooltip title={i18n(dictionary.message.worker_establishment)}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Row>
                }
                name="workerEstablishmentId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {registrationTypes.map((registrationTyp) => (
                    <Option
                      key={registrationTyp.id}
                      title={registrationTyp.name}
                      value={registrationTyp.id}
                    >
                      {registrationTyp.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={
                  <Row>
                    {i18n(dictionary.label.registration_number)}
                    <Tooltip title={i18n(dictionary.message.registration_number)}>
                      <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                    </Tooltip>
                  </Row>
                }
                name="registrationNumber"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }, { max: 14 }]}
                hasFeedback
                validateFirst
              >
                <MaskedInput mask={[{ mask: numericRegExp }]} />
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.workload)}
                name="workload"
                rules={[{ required: sentToEsocial }, { type: "number", min: 0, max: 9999 }]}
                initialValue={0}
                hasFeedback
                validateFirst
              >
                <InputNumber
                  addonAfter={i18n(dictionary.label.weekly)}
                  controls={false}
                />
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.type_of_working_day)}
                name="workingDayTypeId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {typesOfWorkingDay.map((typeOfWorkingDay) => (
                    <Option
                      key={typeOfWorkingDay.id}
                      title={typeOfWorkingDay.name}
                      value={typeOfWorkingDay.id}
                    >
                      {typeOfWorkingDay.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.is_part_time_contract)}
                name="partTimeContractId"
                rules={[{ required: sentToEsocial, message: i18n(dictionary.message.requiredField) }]}
                hasFeedback
                validateFirst
              >
                <Select>
                  {partTimeContracts.map((partTimeContract) => (
                    <Option
                      key={partTimeContract.id}
                      title={partTimeContract.name}
                      value={partTimeContract.id}
                    >
                      {partTimeContract.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.weekly_shift_nighttime)}
                name="hasWeeklyShiftNightTime"
                hasFeedback
                validateFirst
              >
                <Row>
                  <Switch
                    checkedChildren={i18n(dictionary.label.yes)}
                    unCheckedChildren={i18n(dictionary.label.no)}
                    onChange={(checked: boolean) => {
                      form.setFieldsValue({ hasWeeklyShiftNightTime: checked });
                      setHasWeeklyShiftNighttime(checked);
                    }}
                    checked={hasWeeklyShiftNighttime}
                  />
                </Row>
              </FormItem>
              <FormItem
                label={i18n(dictionary.label.description_contractual_weekly_shift)}
                name="descriptionContractualWeeklyShift"
                rules={[{ required: sentToEsocial }, { max: 255 }]}
                hasFeedback
                validateFirst
              >
                <Input
                  placeholder="Horário de trabalho: Segunda à sexta/ Folga: Sábado e..."
                  // addonAfter={
                  //   <Text
                  //     style={{ cursor: "pointer" }}
                  //     onClick={() => {
                  //       setIsWeeklyJourneyModalOpen(true);
                  //     }}
                  //   >
                  //     {i18n(dictionary.insert, { item: "" })}
                  //   </Text>
                  // }
                />
              </FormItem>
            </>
          )}
          <FormItem
            label={labelTootip()}
            name="hasEsocial"
            rules={[{ required: false }]}
            initialValue={false}
            hasFeedback
            validateFirst
          >
            <Row>
              <Switch
                checkedChildren={i18n(dictionary.label.yes)}
                unCheckedChildren={i18n(dictionary.label.no)}
                onChange={(checked: boolean) => {
                  form.setFieldsValue({ hasEsocial: checked });
                  setSentToEsocial(checked);
                }}
                checked={sentToEsocial}
              />
            </Row>
          </FormItem>
          {!!employee?.additionalData && !!employee?.additionalData.admissionFlow && (
            <>
              <FormItem label={i18n(dictionary.label.admission_flow)}>
                <Input
                  style={{ width: "91%", marginRight: "11px" }}
                  value={employee?.additionalData.admissionFlow?.name}
                  disabled
                />
                <Button
                  icon={<EyeOutlined />}
                  onClick={() =>
                    navigate(Routes.ADMISSION_FLOW_EDIT + `/${employee?.additionalData.admissionFlow?.id}`)
                  }
                />
              </FormItem>

              <FormItem label={" "}>
                <Steps
                  current={employee?.additionalData.flowStep}
                  items={employee?.additionalData.admissionFlow.flowSteps.map((step) => ({
                    title: `${i18n(dictionary.label.step)} ${step.index + 1}`,
                    description: (
                      <Text
                        type={employee?.additionalData.flowStep !== step.index ? "secondary" : undefined}
                        style={{ fontSize: "small" }}
                      >
                        {i18n(
                          dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles[
                            step.stepId === 1 ? "t1" : step.stepId === 2 ? "t2" : "t3"
                          ],
                        )}
                      </Text>
                    ),
                    icon:
                      step.stepId === 1 ? (
                        <IdcardOutlined />
                      ) : step.stepId === 2 ? (
                        <AuditOutlined />
                      ) : (
                        <SmileOutlined />
                      ),
                  }))}
                />
              </FormItem>
            </>
          )}
        </Card>
        {(employee?.additionalData?.admissionStatus === 2 ||
          (employee?.admissionDate && employee?.additionalData?.admissionStatus !== 3)) &&
          !employee?.additionalData?.admissionFlow && (
            <Button
              // TODO: ajustar o comportamento do botão "Concluir admissão" ao editar o formulário com um valor inválido
              disabled={/*disableFinishAdmission || !submittable ||*/ loading}
              style={{ marginRight: 16 }}
              onClick={() => {
                if (sentToEsocial) {
                  //mensagem confirmando envio para o esocial
                  InfoMessage({
                    title: i18n(dictionary.confirm.FINISH_ADMISSION_CONFIRM.title),
                    message: i18n(dictionary.confirm.FINISH_ADMISSION_CONFIRM.message),
                    confirm: () => {
                      if (employee.employer.digitalCertificateName) {
                        //mensagem solicitando senha do certificado digital
                        CustomContentInfoMessage({
                          title: i18n(dictionary.info.E_SOCIAL_ALMOST_THERE.title),
                          content: (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: i18n(dictionary.info.E_SOCIAL_ALMOST_THERE.message),
                                }}
                              />
                              <AntdForm
                                form={formPass}
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                style={{ maxWidth: 600, marginTop: 8 }}
                                autoComplete="off"
                                onFinish={formPassHandleSubmit}
                              >
                                <FormItem
                                  name="password"
                                  rules={[{ required: true, message: "Por favor informe a senha do certificado!" }]}
                                >
                                  <Input.Password placeholder="Digite aqui" />
                                </FormItem>
                              </AntdForm>
                            </>
                          ),
                          confirm: async () => {
                            const successAdmission = await finishAdmission(employee.id);
                            if (successAdmission) {
                              formPass.submit();
                            }
                          },
                          okText: i18n(dictionary.action.conclude),
                          cancelText: i18n(dictionary.action.cancel),
                        });
                      } else {
                        //mensagem indicando que o certificado digital deve ser informado na tela de empregador
                        InfoMessage({
                          title: i18n(dictionary.error.E_SOCIAL_INTEGRATION_ERROR.title),
                          message: i18n(dictionary.error.E_SOCIAL_INTEGRATION_ERROR.message),
                          confirm: () => {
                            navigate(Routes.EMPLOYER);
                          },
                        });
                      }
                    },
                  });
                } else {
                  finishAdmission(employee.id);
                }
              }}
            >
              Concluir admissão
            </Button>
          )}
        {employee?.additionalData?.admissionStatus === 3 && showEsocialButton && (
          <Button
            style={{ marginRight: 16 }}
            onClick={() => {
              if (employee.employer.digitalCertificateName) {
                //mensagem solicitando senha do certificado digital
                CustomContentInfoMessage({
                  title: i18n(dictionary.info.E_SOCIAL_ALMOST_THERE.title),
                  content: (
                    <>
                      <div dangerouslySetInnerHTML={{ __html: i18n(dictionary.info.E_SOCIAL_ALMOST_THERE.message) }} />
                      <AntdForm
                        form={formPass}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 600, marginTop: 8 }}
                        autoComplete="off"
                        onFinish={formPassHandleSubmit}
                      >
                        <FormItem
                          name="password"
                          rules={[{ required: true, message: "Por favor informe a senha do certificado!" }]}
                        >
                          <Input.Password placeholder="Digite aqui" />
                        </FormItem>
                      </AntdForm>
                    </>
                  ),
                  confirm: () => {
                    formPass.submit();
                  },
                  okText: i18n(dictionary.action.conclude),
                  cancelText: i18n(dictionary.action.cancel),
                });
              } else {
                //mensagem indicando que o certificado digital deve ser informado na tela de empregador
                InfoMessage({
                  title: i18n(dictionary.error.E_SOCIAL_INTEGRATION_ERROR.title),
                  message: i18n(dictionary.error.E_SOCIAL_INTEGRATION_ERROR.message),
                  confirm: () => {
                    navigate(Routes.EMPLOYER);
                  },
                });
              }
            }}
          >
            {i18n(dictionary.action.resend)}
          </Button>
        )}
        {!!employee?.additionalData?.admissionFlow &&
          Number(employee?.additionalData?.flowStep) + 1 < employee?.additionalData?.admissionFlow.flowSteps.length && (
            <Button
              style={{ marginRight: 16 }}
              onClick={completeStep}
            >
              {i18n(dictionary.label.complete_step)}
              &nbsp;
              {Number(employee?.additionalData?.flowStep) + 1}
            </Button>
          )}
      </Form>
      {/* <WeeklyJourneyModal
        journeyTypes={journeyTypes}
        isModalOpen={isWeeklyJourneyModalOpen}
        setIsModalOpen={setIsWeeklyJourneyModalOpen}
        shiftSchedule={employee?.shiftSchedule}
      /> */}
    </>
  );
}

export default TabAdmissionalData;
